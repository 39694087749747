//@flow
import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { RelevanceTag } from '@dt/material-components';
import { PolicyRuleTypeRelevanceValues } from '@dt/graphql-support/types';

export type PopoutFilterPluginRelevanceValues = {
  IMPORTANT?: boolean,
  PROACTIVE?: boolean,
  URGENT?: boolean,
  ...
};

type Props = {
  +value: PopoutFilterPluginRelevanceValues,
  +onChange: (
    (
      value: PopoutFilterPluginRelevanceValues,
    ) => PopoutFilterPluginRelevanceValues,
  ) => void,
};

/*
 * Popout filter plugin.
 * User can select relevances.
 *
 * @param value - Current state.
 * @param onChange - State change.
 */
const PopoutFilterPluginRelevanceComponent = function PopoutFilterPluginRelevance({
  value,
  onChange,
}: Props) {
  return (
    <Box display="flex" flexDirection="column">
      {[
        PolicyRuleTypeRelevanceValues.URGENT,
        PolicyRuleTypeRelevanceValues.IMPORTANT,
        PolicyRuleTypeRelevanceValues.PROACTIVE,
      ].map(relevance => (
        <FormControlLabel
          key={relevance}
          label={<RelevanceTag relevance={relevance} size="medium" />}
          control={
            <Checkbox
              color="primary"
              inputProps={{
                'aria-label': `${relevance}`,
              }}
              checked={!!value[relevance]}
              onChange={e => {
                (value =>
                  onChange(prev => ({
                    ...prev,
                    // $FlowFixMe - string -> enum
                    [relevance]: value,
                  })))(e.target.checked);
              }}
            />
          }
        />
      ))}
    </Box>
  );
};

export const PopoutFilterPluginRelevance = PopoutFilterPluginRelevanceComponent;
